<template>
  <b-sidebar
    id="add-new-filter-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    :no-close-on-backdrop="$refs.refFormObserver && $refs.refFormObserver.flags.dirty"
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="fixed-sidebar-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 add-edit-sidebar-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Filter Name -->
          <validation-provider
            #default="validationContext"
            name="Filter Name"
            rules="required"
          >
            <b-form-group
              label="Filter Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                maxlength="255"
                autofocus
                :state="getValidationState(validationContext)"
                :plaintext="isReadonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Criteria"
            rules="required"
          >
            <b-form-group
              label="Criteria"
              label-for="criteria"
            >
              <b-form-tags
                id="criteria"
                v-model="form.criteria"
                plaintext
                placeholder=""
                :state="getValidationState(validationContext)"
                :disabled="true"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Is Filter Private -->
          <validation-provider
            #default="validationContext"
            name="Filter Name"
            rules="required"
          >
            <b-form-group
              label-for="private"
            >
              <b-form-checkbox
                id="private"
                v-model="form.private"
                autofocus
                :state="getValidationState(validationContext)"
              >
                Private
                <b-badge
                  v-b-tooltip.hover.top="'Private filters are visible only to you.'"
                  variant="light-primary"
                >
                  ?
                </b-badge>
              </b-form-checkbox>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div
            class="mt-2 fixed-footer-form-actions"
            :class="{'d-flex' : isReadonly || !isEditing}"
          >
            <div v-if="!isReadonly">
              <div
                v-if="isEditing"
                class="d-flex justify-content-between"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
                <div>
                  <b-button
                    v-for="el in btns"
                    :key="el"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="el === 'Save' ?'outline-primary' : 'primary'"
                    :class="{'mr-2 ': el === 'Save'}"
                    type="submit"
                    :disabled="isLoading"
                    @click="submitState = el"
                  >
                    {{ el }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTags, BFormCheckbox, BBadge, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTags,
    BFormCheckbox,
    BBadge,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    isReadonly: {
      type: Boolean,
    },
    value: {
      type: Object,
      default: () => this.getDefaultForm(),
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      form: this.getDefaultForm(),
      btns: ['Save', 'Save & Close'],
      submitState: '',
    }
  },
  computed: {
    ...mapState('app', [
      'isLoading',
    ]),
    isEditing() {
      return !!this.value
    },
    title() {
      if (this.isReadonly) {
        return 'Filter Details'
      }
      return this.isEditing ? 'Edit Filter' : 'Add New Filter'
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.form = {
          ...value,
          __typename: undefined,
        }
      }
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    resetForm() {
      this.$emit('update:value', this.getDefaultForm())
      this.$nextTick(() => {
        this.$refs.refFormObserver.reset()
      })
      this.form = this.getDefaultForm()
    },
    onSubmit() {
      delete this.form.owner
      const action = this.isEditing
        ? this.$store.dispatch('filter/updateFilter', this.form)
        : this.$store.dispatch('filter/addFilter', this.form)

      action
        .then(() => {
          if (this.submitState === 'Save') {
            this.$emit('update:is-sidebar-active', true)
          } else {
            this.$emit('update:is-sidebar-active', false)
          }
        })
    },
    getDefaultForm() {
      return {
        name: '',
        private: '',
        criteria: '',
        associatedEntity: '',
      }
    },
  },
}
</script>

<style lang="scss">

#add-new-filter-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
