<template>
  <div>
    <!-- Filter sidebar -->
    <filter-add
      :is-sidebar-active.sync="isAddNewFilterSidebarActive"
      :is-readonly="isAddNewFilterSidebarInDisplayMode"
      :value.sync="activeRecord"
    />

    <!-- Table Container Card -->
    <div
      no-body
      class="container-card"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="12"
            offset="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <div
                style="position: absolute; left: 28px; top: 7px;"
                @click="refetchData"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Perform Search'"
                  size="18"
                  icon="SearchIcon"
                  class="mr-1"
                />
              </div>
              <b-form-input
                v-model="searchQuery"
                style="padding-left: 40px !important;"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="refetchData"
              />
              <div
                v-if="searchQuery.length !== 0"
                style="position: absolute; top: 7px; right: 42px"
                @click="clearSearch"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Clear Search'"
                  icon="XIcon"
                  size="18"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refFilterListTable"
        class="position-relative"
        :items="filtersAccessibleToCurrentUser.nodes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        :busy="isLoading"
        hover
        show-empty
        empty-text="No matching records found"
        @row-clicked="redirectToEntityPageWithFilterApplied"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-1">
            <b-spinner
              type="grow"
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>

        <!-- Column: Name -->
        <!--<template #cell(name)="data">
          <a :href="getFilterRedirectUrl(data.item)">{{ data.item.name }}</a>
        </template>-->

        <!-- Column: Name -->
        <template #cell(owner)="data">
          <div>{{ data.item.owner.name }}</div>
        </template>

        <!-- Column: Private -->
        <template #cell(private)="data">
          <div v-if="data.item.private">
            Yes
          </div>
          <div v-else>
            No
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div
            v-if="data.item.owner.id === currentUser.id"
            class="action-buttons"
          >
            <b-button
              :id="`row-${data.item.id}-edit-icon`"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="editRecord(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Edit"
              class="cursor-pointer"
              :target="`row-${data.item.id}-edit-icon`"
            />

            <b-button
              :id="`row-${data.item.id}-delete-icon`"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteRecord(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Delete"
              class="cursor-pointer"
              :target="`row-${data.item.id}-delete-icon`"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ paginationMeta.from }} to {{ paginationMeta.to }} of {{ paginationMeta.of }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="5"
            sm="5"
            class="d-flex align-items-center justify-content-center"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="filtersAccessibleToCurrentUser.totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

          <!-- Per Page -->
          <b-col
            cols="4"
            sm="4"
            class="d-flex align-items-center justify-content-end"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              append-to-body
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BTooltip,
  BSpinner, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { debounce } from 'vue-debounce'
import { mapState, mapActions } from 'vuex'
import { createPopper } from '@popperjs/core'
import FilterAdd from '@/views/filter/FilterAdd.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BTooltip,
    BSpinner,
    vSelect,

    FilterAdd,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      tableColumns: [
        { key: 'name', sortable: true, class: 'name' },
        { key: 'associatedEntity' },
        { key: 'owner' },
        { key: 'private', label: 'private?' },
        { key: 'actions' },
      ],
      isAddNewFilterSidebarActive: false,
      isAddNewFilterSidebarInDisplayMode: false,
      activeRecord: {},
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      currentPage: 1,
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
    }
  },
  computed: {
    ...mapState('filter', ['filtersAccessibleToCurrentUser', 'isLoading']),
    ...mapState('user', ['currentUser']),
    paginationMeta() {
      return {
        from:
            this.perPage * (this.currentPage - 1)
            + (this.filtersAccessibleToCurrentUser.nodes.length ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.filtersAccessibleToCurrentUser.nodes.length,
        of: this.filtersAccessibleToCurrentUser.totalCount,
      }
    },
    offsetAndLimitAndSortByAndIsDescAndAssociatedEntities() {
      return `${this.perPage}|${this.currentPage}|${this.sortBy}|${this.isDesc}|${this.associatedEntities}`
    },
    queryParams() {
      return {
        q: this.searchQuery,
        offset: this.perPage * (this.currentPage - 1),
        limit: this.perPage,
        sortBy: this.sortBy,
        isDesc: this.isSortDirDesc,
        associatedEntities: ['Role', 'Process', 'User', 'Task'],
      }
    },
  },
  watch: {
    offsetAndLimitAndSortByAndIsDescAndAssociatedEntities: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
    searchQuery: {
      handler(value) {
        this.queryParams.q = value
      },
      deep: true,
    },
  },
  created() {
    this.onLoad()
  },
  methods: {
    ...mapActions('filter', ['getFiltersAccessibleToCurrentUser']),
    ...mapActions('user', ['fetchCurrentUserIfNotLoaded']),
    refetchData: debounce(function cb() {
      this.onLoad()
    }, '300ms'),
    onLoad() {
      const { entity } = this.$route.query

      if (entity) {
        this.queryParams.associatedEntities = [entity]
      }
      this.getFiltersAccessibleToCurrentUser(this.queryParams)
    },
    editRecord(activeRecord) {
      this.activeRecord = activeRecord
      this.isAddNewFilterSidebarActive = true
      this.isAddNewFilterSidebarInDisplayMode = false
    },
    displayRecord(activeRecord) {
      this.editRecord(activeRecord)
      this.isAddNewFilterSidebarInDisplayMode = true
    },
    deleteRecord(activeRecord) {
      this.$bvModal
        .msgBoxConfirm('Are you sure that you want to delete this filter?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$store.dispatch('filter/removeFilter', activeRecord.id)
          }
        })
    },
    // redirection
    getFilterRedirectUrl(filter) {
      if (filter.associatedEntity === 'User') return `people?q=${filter.criteria}&people?qd=${filter.criteria}`
      if (filter.associatedEntity === 'Process') return `processes?q=${filter.criteria}&processes?qd=${filter.criteria}`
      return `${filter.associatedEntity.toLowerCase()}s?q=${filter.criteria}`
    },
    getEntityPath(filter) {
      let path = '/'
      if (filter.associatedEntity === 'User') {
        path = '/people'
      } else if (filter.associatedEntity === 'Process') {
        path = '/processes'
      } else if (filter.associatedEntity === 'Role') {
        path = '/roles'
      } else if (filter.associatedEntity === 'Task') {
        path = '/tasks'
      }
      return path
    },
    redirectToEntityPageWithFilterApplied(record) {
      const path = this.getEntityPath(record)
      this.$router.push({ path, query: { q: record.criteria } })
    },

    // search related methods
    clearSearch() {
      this.searchQuery = ''
      this.refetchData()
    },

    // popper
    withPopper(dropdownList, component, { width }) {
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}
.action-buttons {
  margin-left: -10px;

  button {
    margin-right: 4px;
  }
}
  .truncate{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
.name{
  @extend .truncate;
    width: 400px;
    max-width: 400px;
    min-width: 400px;
}
  .container-card{
  overflow: auto;
  background-color: white;
  border: none;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 0.428rem;
}

</style>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
</style>
